.section1-container {
  background-color: #b5b5b9;
  background-image: url(../../LandingPage/Assets/LandinPage.png);
  height: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
}
.section1-container h1 {
  padding: 0;
  margin: 0;
  font-size: var(--fontSize_Heading_PC);
}
.section1-container button {
  width: 284px;
  background: transparent;
  border-color: white;
  color: white;
}

.section1-container button:hover {
  background-color: white;
  border-color: white;
  color: #eb5a02;
}
.section1-container .header1 {
  color: white;
}
.section1-container .header2 {
  background: linear-gradient(242.29deg, #ff00ff 25.29%, #46e6e6 103.65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (max-width: 1200px) {
  .section1-container {
    padding-top: 3em;
    background-image: url(../../LandingPage/Assets/landingBGMobile.png);
  }
  .section1-container h1 {
    text-align: center;
    font-size: var(--fontSize_Heading_Mobile);
  }
  .section1-container .header2 {
    flex-direction: column;
    gap: 0;
  }
  .section1-container button {
    width: 150px;
  }
}
