.footer_root {
  background-color: black;
  color: white;
  padding: 1em;
}
.left_Container {
  color: white;
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
.left_Container text {
  max-width: 313px;
}
.left_Container img {
  max-width: 225px;
  max-height: 54px;
}
.right_Container {
  width: 60%;
  color: white;
  display: flex;
  justify-content: space-evenly;
}
.root_Container {
  display: flex;
  padding: 5em;
  justify-content: space-between;
}
.logo_Container {
  display: flex;
  justify-content: space-between;
  max-width: 248px;
}
.resource_container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.footer_input {
  background-color: #eb5a02;
  border-color: white;
  color: white;
  width: 70%;
  outline: 0;
  border: 2px solid white;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  padding-left: 1em;
}
.footer_input::placeholder {
  color: white;
}

.subscribe_container {
  display: flex;
  width: 100%;
}
.subscribe_container button {
  padding: 1em;
  border-top-right-radius: 1em;
  border: none;
  margin: 0;
  border-bottom-right-radius: 1em;
}
.subscribe_container button:hover {
  cursor: pointer;
}

.resource_container a {
  text-decoration: none;
  color: white;
}

.resource_container a:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .footer_root {
    text-align: center;
  }
  .footer_root text {
    font-size: 0.8em;
  }
  .root_Container {
    flex-direction: column;
    align-items: center;
    padding: 2em;
    gap: 2em;
  }
  .left_Container {
    width: 100%;
    justify-content: center;
  }
  .right_Container {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2em;
  }

  .resource_container {
    gap: 0.5em;
  }
}
