:root {
  --fontSize_Heading_PC: 3rem;
  --fontSize_Heading_Mobile: 2rem;
  --fontSize_Heading2_Mobile: 1.5rem;
  --stats-heading_PC: 1.3rem;
  --stats-heading_Mobile: 1rem;
  --stats-Content_PC: 0.9rem;
  --stats-Content_Mobile: 0.8rem;
}

.root {
  width: inherit;
  height: 100vh;
}
.navBar-Container {
  position: absolute;
  width: inherit;
  background-color: transparent;
  background-position: center;
  display: flex;
  color: white;
  align-items: center;
  padding: 1em;
  justify-content: space-between;
  z-index: 999;
}
.navBar-Container img {
  content: url(./Assets/pcLogo.png);
  object-fit: cover;
}

@media only screen and (max-width: 1200px) {
  .navBar-Container {
    padding: 0.5em;
  }
  .navBar-Container img {
    content: url(./Assets/Logo.png);
  }
}
