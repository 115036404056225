.root1 {
  color: #6392c9 !important;
}
.Content-Container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.closeBTN_dialog {
  margin: 1em;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 100%;
  padding-inline: 1em;
  padding-block: 0.8em;
  cursor: pointer;
}
.closeBTN_dialog:hover {
  background: rgba(90, 90, 90, 0.6);
}
.iframe_class {
  height: 1080px;
}
@media only screen and (max-width: 1200px) {
  .Content-Container {
    overflow-y: auto;
  }
}
