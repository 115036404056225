.leaderBoard_root {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-family: Poppins;
  height: 500px;
}
.leaderBoard_Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.leaderboard_columns {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.leaderBoard_root header {
  font-weight: bold;
  color: #000;
  letter-spacing: 0.2rem;
}
.leaderBoard_CloseBTN {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 100%;
  padding-inline: 0.8em;
  padding-block: 0.5em;
  cursor: pointer;
}
.leaderBoard_CloseBTN:hover {
  background-color: lightgray;
}
@media only screen and (max-width: 1200px) {
  .leaderBoard_CloseBTN {
    padding-inline: 0;
    padding-block: 0;
  }
}
