.left_container {
  position: fixed;
  bottom: 0;
  left: 0;
  gap: 0.3em;
  margin-left: 1em;
  display: -webkit-inline-box;
  margin-bottom: 1em;
}
.right_container {
  position: fixed;
  bottom: 0;
  right: 0;
  gap: 0.3em;
  margin-right: 1em;
  display: -webkit-inline-box;
  margin-bottom: 1em;
}
.blob {
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  height: auto;
  width: auto;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.9);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 7px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}
@media only screen and (max-width: 1200px) {
  #mobiledisable {
    display: none;
  }
}
