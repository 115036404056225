.ShareInput {
  width: 80%;
  padding: 2%;
  margin: 0;
  outline: 0;
  border: 0;
  background-color: #e5e5e5;
  color: black;
  backdrop-filter: saturate(180%) blur(5px);
  font-weight: bold;
}
.closeBTN {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 100%;
  padding-inline: 0.8em;
  padding-block: 0.5em;
  cursor: pointer;
}
.closeBTN:hover {
  background-color: lightgray;
}
.copyButton {
  margin: 0;
  outline: 0;
  border: 0;
  background-color: #e5e5e5;
  color: black;
  backdrop-filter: saturate(180%) blur(5px);
  cursor: pointer;
  width: 20%;
  font-weight: bold;
}
.copyButton:hover {
  background: rgba(70, 66, 66, 0.8);
}
