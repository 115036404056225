.ResendOTP {
  color: #eb5a02;
  font-weight: 500;
}
.ResendOTP:hover {
  cursor: pointer;
}

.otp_input input {
  padding: 0.8em;
  border: 2px solid #cacad1;
  border-radius: 0.5em;
  text-decoration: underline;
  color: grey;
  text-align: center;
  font-size: large;
}
[data-resetdisabled="true"] {
  color: #eb8240;
  cursor: default !important;
}
@media only screen and (max-width: 1200px) {
  .otp_input {
    gap: 0.5em;
  }
  .otp_input input {
    padding: 0.6em;
  }
}
