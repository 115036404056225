.login_container {
  height: 100vh;
  width: inherit;
  background-image: url(../assets/PCBuildBG.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.login_Box {
  height: auto;
  width: 542px;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_data_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3em;
  color: #8c8c8c;
  gap: 2em;
  width: 100%;
}
.login_data_container header {
  font-weight: bold;
  font-size: 1.5rem;
  color: black;
}

.bottom_logo {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 1em;
  content: url(../LandingPage/Assets/WhiteLogo.png);
  width: 15%;
}
.login_data_container .custom-btn {
  background-color: #eb5a02;
  color: white;
  width: 100%;
}
.login_data_container .custom-btn:hover {
  background-color: white;
  color: #eb5a02;
}
.login_data_container .custom-btn:disabled {
  background-color: #eb8240;
  cursor: default;
  color: white;
}

@media only screen and (max-width: 1200px) {
  .login_Box {
    width: 100%;
    height: 100%;
  }
  .bottom_logo {
    content: url(../LandingPage/Assets/blackLogo.png);
    position: static;
    width: auto;
  }
  .login_data_container {
    padding-inline: 1em;
    padding-block: 0em;
    gap: 1em;
  }
}
