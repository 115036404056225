.loading_root {
  background-image: url(../assets/PCBuildBG.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
#t-2 {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  width: 100%;
  padding: 2em;
}
#t-1 {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  width: 100%;
  padding: 2em;
}
.inner {
  position: absolute;
  width: 23rem;
  height: 23rem;
  border-radius: 50%;
}
.inner img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.inner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border-top: 2px solid #ea884c;
  border-right: 2px solid #ea884c;
  animation: animated 2s linear infinite;
}
@keyframes animated {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1200px) {
  .inner {
    width: 15rem;
    height: 15rem;
  }
  .inner img {
    width: 50%;
  }
}
