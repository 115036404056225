.BTN {
  outline: 0;
  border: 2px solid;
  color: var(--outline_Color);
  padding: 1em;
  font-weight: bold;
  transition: 0.2s;
  border-radius: 0.8em;
}
.BTN:hover {
  cursor: pointer;
}

.BTN:disabled {
  cursor: default;
}

@media only screen and (max-width: 1200px) {
  .BTN {
    padding: 0.8em;
    border-radius: 0.5em;
  }
}
