.section3_root {
  background-image: url(../../assets/PCBuildBG.png);
  height: inherit;
  width: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  flex-direction: column;
}

.section3_root header {
  font-weight: bold;
  font-size: var(--fontSize_Heading_PC);
  text-align: center;
  margin-top: 1em;
}

@media only screen and (max-width: 1200px) {
  .section3_root header {
    font-size: var(--fontSize_Heading2_Mobile);
  }
}
