.container {
  background-color: black;
  height: auto;
  width: inherit;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2em;
}

.container header {
  color: white;
  font-weight: bold;
  font-size: var(--fontSize_Heading_PC);
  margin-top: 1em;
}
.stats-container {
  align-self: center;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-inline: 2em;
  padding-block: 4em;
  height: inherit;
  gap: 1em;
}
.img_Holder img {
  width: 100%;
  object-fit: cover;
  border-radius: 1em;
}
.statsHolder {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 30%;
}
.statsHolder h1 {
  color: white;
  font-size: var(--stats-heading_PC);
}

.data-container {
  padding: 1%;
  border-radius: 1em;
}
.data-container:hover {
  cursor: pointer;
  height: auto;
}
.data-container h1 {
  margin-left: 3%;
}
.data-container h4 {
  font-weight: 400;
  line-height: 2em;
  color: white;
  overflow: hidden;
  margin: 0;
  transition: transform 0.26s ease-in-out, opacity 0.26s ease-in;
  font-size: var(--stats-Content_PC);
}

.increaseHeight {
  transform: translateY(0);
  opacity: 1;
  height: auto;
  padding: 1em;
}
.decreaseHeight {
  transform: translateY(-1em);
  opacity: 0;
  height: 0;
  padding: 0;
}
.img_Holder {
  width: 60%;
  overflow-x: auto;
}

@media only screen and (max-width: 1200px) {
  .stats-container {
    flex-direction: column;
    text-align: center;
  }
  .container header {
    font-size: var(--fontSize_Heading2_Mobile);
  }
  .img_Holder {
    width: 100%;
  }
  .img_Holder img {
    width: 200%;
  }
  .statsHolder {
    width: 100%;
  }
  .statsHolder h1 {
    font-size: var(--stats-heading_Mobile);
  }
  .statsHolder h4 {
    font-size: var(--stats-Content_Mobile);
  }
}
