.section4_root {
  height: auto;
  width: inherit;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}
.section4_root header {
  font-size: var(--fontSize_Heading_PC);
  color: white;
  font-weight: bold;
  margin-top: 1em;
}

.merch_Container {
  display: flex;
  gap: 2em;
  padding: 2em;
  scroll-behavior: smooth;
}
.section4_root .custom-BTN {
  width: 284px;
  background: transparent;
  border-color: #eb5a02;
  color: #eb5a02;
}
.section4_root .custom-BTN:hover {
  background-color: white;
  border-color: white;
}
.brand_Container {
  display: flex;
  gap: 2em;
  padding: 4em;
}
.merch {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
}
.merch_child {
  background-color: #c4c4c4;
  height: inherit;
  width: inherit;
}

.partner {
  background-color: #c4c4c4;
  width: 200px;
  height: 200px;
}
.mobile-merch-btn-container {
  display: none;
  gap: 1em;
}
.icon-Btn {
  display: flex;
  align-items: center;
  padding: 0.5em;
  margin: 0;
  border: 0;
  background: transparent;
  border: 1px solid grey;
  border-radius: 100px;
}
.icon-Btn img {
  padding-block: 0.6em;
  padding-inline: 0.5em;
}

@media only screen and (max-width: 1200px) {
  .section4_root header {
    font-size: var(--fontSize_Heading2_Mobile);
  }
  .section4_root {
    gap: 1em;
  }
  .partner {
    width: 83px;
    height: 83px;
  }
  .mobile-merch-btn-container {
    display: flex;
  }
  .merch_Container {
    padding: 0;
    width: 100%;
    display: -webkit-inline-box;
    overflow-x: hidden;
  }
  .merch {
    width: 100%;
  }
  .brand_Container {
    padding: 1em;
    padding-bottom: 3em;
  }
  .merch_child {
    width: 80%;
  }
  .section4_root .custom-BTN {
    width: 150px;
  }
}
