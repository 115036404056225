.user_input_Holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.user_input_Holder input[type="text"] {
  border: 1px solid #cacad1;
  border-radius: 1em;
  width: 90%;
  color: grey;
  font-weight: 500;
  font-size: 14px;
  padding: 1em;
}

@media only screen and (max-width: 1200px) {
  .details_Container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
