.exp_Container {
  position: fixed;
}
.option_container {
  position: fixed;
  display: flex;
  gap: 0.5em;
  right: 0;
  margin: 1em;
}
.exp_BTN {
  margin: 0;
  border: 0;
  padding-inline: 1em;
  padding-block: 0.8em;
  background-color: white;
  color: grey;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  height: 40px;
}
.usersCount_container {
  background-color: white;
  padding-inline: 1em;
  padding-block: 0.5em;
  border-radius: 0.5em;
  position: fixed;
  left: 0;
  margin: 1em;
  color: #666666;
  display: flex;
  gap: 0.5em;
  justify-content: space-around;
  align-items: center;
}
.usersCount_container img {
  width: 50%;
  object-fit: cover;
}
.usersCount_container text {
  font-weight: 500;
}
#menuOption {
  display: none;
}
@media only screen and (max-width: 1200px) {
  #menuOption {
    display: block;
  }
}
