:root {
  --drawerwidth: 400px;
}
.drawer {
  height: 100vh;
  width: var(--drawerwidth);
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  backdrop-filter: blur(5px);
}
.chat_container {
  position: relative;
  width: 100%;
  height: 100%;
}
.Toggler_Container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.Toggler_Container button {
  cursor: pointer;
  padding: 1em;
  width: 45%;
  border: 0;
  background: transparent;
}
[data-selectedtab="true"] {
  border-bottom: 2px solid #473bb1 !important;
  transition: 1 ease-in;
}
[data-selectedtab="false"] {
  border-bottom: 2px solid #d6d6d6 !important;
  transition: 0.2s ease-in;
  animation: animation;
}
@keyframes animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.chat_input_holder {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 0.5em;
}
.chat_input {
  width: 70%;
  border: 0;
  padding-block: 1em;
}
[data-openchat="true"] {
  transform: translateX(0px);
  transition: 0.5s ease-in-out;
}
[data-openchat="false"] {
  transform: translateX(var(--drawerwidth));
  transition: 0.5s ease-in;
}
.chat_input_holder button {
  border: 0;
  background: none;
  padding: 0;
  width: 2em;
  cursor: pointer;
}
@media only screen and (max-width: 1200px) {
  .drawer {
    width: 100vw;
  }
  [data-openchat="true"] {
    transform: translateX(0px);
    transition: 0.5s ease-in-out;
  }
  [data-openchat="false"] {
    transform: translateX(100vw);
    transition: 0.5s ease-in;
  }
}
