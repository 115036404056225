.section5_root {
  height: auto;
  width: 100%;
  background-image: url(../../assets/PCBuildBG.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-around;
}

.img {
  max-width: 500px;
  content: url("../../../../public/test.png");
}
.section5_container_1 {
  width: 40%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}
.section5_container_2 {
  width: 40%;
  height: inherit;
  color: #757575;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 2em;
}
.section5_container_2 header {
  color: #eb5a02;
  font-size: var(--fontSize_Heading_PC);
  font-weight: bold;
}
.section5_container_2 text {
  font-size: var(--stats-Content_PC);
}
.input_container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.input_container textarea,
.input_container input {
  padding: 1em;
  outline: 0;
  border: 0;
  background: transparent;
  border: 2px solid #757575;
  border-radius: 1em;
  color: #757575;
}
.input_container .custom-btn {
  width: 100%;
  background: transparent;
  border-color: white;
  color: white;
}

.input_container .custom-btn:hover {
  background-color: white;
  border-color: white;
  color: #eb5a02;
}
.input_container .custom-btn:disabled {
  background: transparent;
  color: rgb(161, 161, 161);
  border-color: rgb(161, 161, 161);
}
@media only screen and (max-width: 1200px) {
  .section5_root {
    flex-direction: column;
    align-items: center;
  }
  .img {
    width: 100%;
  }
  .section5_container_1 {
    width: 80%;
    margin: 1em;
  }
  .section5_container_2 {
    width: 80%;
    margin: 1em;
  }
  .section5_container_2 header {
    font-size: var(--fontSize_Heading2_Mobile);
  }
}
