.Message_Container {
  background-color: #cacad1;
  border-radius: 1em;
  padding-inline: 1em;
  margin-inline: 2.5em;
  padding-block: 0.5em;
}
.initials_container {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.messagePanel {
  overflow-y: auto;
  height: 93%;
  max-height: fit-content;
}
.Chatinput_container {
  display: flex;
  flex-direction: column;
  gap: 0.1em;
  width: 100%;
  position: absolute;
  bottom: 0;
}
