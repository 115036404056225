.participant_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  padding: 1em;
}
.container_participant_root {
  overflow-y: auto;
  max-height: fit-content;
  height: 93%;
}
.name_Container {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.controls_container {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
/* .controls_container button {
  border: 0;
  background: transparent;
  padding-block: 0.5em;
  padding-inline: 0.5em;
  border-radius: 100%;
}
.controls_container button:hover {
  background-color: rgb(218, 218, 218);
} */
